var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-semibold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("import_orders")) + " ")])])]), _c('div', {
    staticClass: "mx-6 md:flex mb-6 border p-5 border-dotted rounded-md border-gray-500"
  }, [_vm.sellers.length > 0 ? _c('div', {
    staticClass: "md:w-1/2 px-3 mb-6 md:mb-0"
  }, [_c('div', {
    staticClass: "border-2 h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("select_seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "options": _vm.sellers
    },
    on: {
      "input": _vm.setSelected
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "md:w-1/2 px-3"
  }, [_c('div', {
    staticClass: "border-2 h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v("Country (" + _vm._s(_vm.$t("warehouse")) + ") "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehousesUser,
      "label": "countryName"
    },
    on: {
      "input": _vm.setSelectedW
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.country)
          }
        }), _vm._v(" " + _vm._s(option.countryName) + " ")];
      }
    }])
  })], 1)])])]), _c('div', {
    staticClass: "px-6 mt-3"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "border border-dashed border-gray-500"
  }, [_c('input', {
    ref: "csvOrdersFile",
    staticClass: "cursor-pointer relative block opacity-0 w-full h-full p-20 z-50",
    attrs: {
      "accept": ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.processFile($event);
      }
    }
  }), _c('div', {
    staticClass: "text-center p-10 absolute top-0 right-0 left-0 m-auto"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("drag_file")))]), this.selectedFileName ? _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("selected_file")) + " "), _c('span', {
    staticClass: "text-green-500"
  }, [_vm._v(_vm._s(this.selectedFileName))])]) : _vm._e()])]), _c('div', {
    staticClass: "p-5 grid justify-items-center"
  }, [_c('button', {
    staticClass: "flex items-stretch rounded-md mx-1 border border-gray-100 bg-green-600 hover:bg-green-600 text-white py-2 px-4 focus:outline-none loidingconfirm relative",
    class: _vm.loading ? 'noselect' : '',
    attrs: {
      "icon": "publish",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.uploadOrders
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('i', {
    staticClass: "self-center material-icons"
  }, [_vm._v("publish")]), _vm._v(" " + _vm._s(_vm.$t("upload")) + " ")], 1)])], 1), _vm.messages.length > 0 ? _c('div', [_c('table', {
    staticClass: "min-w-full leading-normal table-messages"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("orderid")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("message")) + " ")])])]), _vm._l(_vm.messages, function (item, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.orderId) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900",
      class: item.type
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])]);
  })], 2)]) : _vm._e(), _c('button', {
    staticClass: "flex items-stretch rounded-md mx-1 border border-gray-100 bg-green-600 hover:bg-green-600 text-white py-2 px-4 focus:outline-none",
    attrs: {
      "icon": "publish"
    },
    on: {
      "click": _vm.downloadcsvExample
    }
  }, [_c('i', {
    staticClass: "self-center material-icons"
  }, [_vm._v("file_download")]), _vm._v(" " + _vm._s(_vm.$t("download_xlsx_example")) + " ")])]), _c('br')])]);

}
var staticRenderFns = []

export { render, staticRenderFns }